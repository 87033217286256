
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import Step1 from "@/views/pages/wizards/steps/Step1.vue";
import Step2 from "@/views/pages/wizards/steps/Step2.vue";
import Step3 from "@/views/pages/wizards/steps/Step3.vue";
import Step4 from "@/views/pages/wizards/steps/Step4.vue";
import Step5 from "@/views/pages/wizards/steps/Step5.vue";

interface Step1 {
  accountType: string;
}

interface Step2 {
  accountTeamSize: string;
  accountName: string;
  accountPlan: string;
}

interface Step3 {
  businessName: string;
  businessDescriptor: string;
  businessType: string;
  businessDescription: string;
  businessEmail: string;
}

interface Step4 {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

interface CreateAccount extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<CreateAccount>({
      accountType: "personal",
      accountTeamSize: "50+",
      accountName: "",
      accountPlan: "1",
      businessName: "Keenthemes Inc.",
      businessDescriptor: "KEENTHEMES",
      businessType: "1",
      businessDescription: "",
      businessEmail: "corp@support.com",
      nameOnCard: "Max Doe",
      cardNumber: "4111 1111 1111 1111",
      cardExpiryMonth: "1",
      cardExpiryYear: "2",
      cardCvv: "123",
      saveCard: "1"
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
    });

    const createAccountSchema = [
      Yup.object({
        accountType: Yup.string()
          .required()
          .label("Account Type")
      }),
      Yup.object({
        accountName: Yup.string()
          .required()
          .label("Account Name")
      }),
      Yup.object({
        businessName: Yup.string()
          .required()
          .label("Business Name"),
        businessDescriptor: Yup.string()
          .required()
          .label("Shortened Descriptor"),
        businessType: Yup.string()
          .required()
          .label("Corporation Type"),
        businessEmail: Yup.string()
          .required()
          .label("Contact Email")
      }),
      Yup.object({
        nameOnCard: Yup.string()
          .required()
          .label("Name On Card"),
        cardNumber: Yup.string()
          .required()
          .label("Card Number"),
        cardExpiryMonth: Yup.string()
          .required()
          .label("Expiration Month"),
        cardExpiryYear: Yup.string()
          .required()
          .label("Expiration Year"),
        cardCvv: Yup.string()
          .required()
          .label("CVV")
      })
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value
      }
    });

    const handleStep = handleSubmit(values => {
      console.log(values);

      formData.value = {
        ...formData.value,
        ...values
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary"
        }
      }).then(() => {
        window.location.reload();
      });
    };

    return {
      horizontalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex
    };
  }
});
